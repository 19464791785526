<template lang="vi">
<div>
    <page-header :title="`Chi tiết thông tin`" :items="breadcrumbs" />
    <div v-if="!loading">
        <b-row>
            <b-col lg="12">
                <b-card>
                    <b-form-group>
                        <label>Tiêu đề</label>
                        <div style="border: solid 1px #ccc; border-radius: 5px; padding: 10px;" v-html="form.ten"></div>
                    </b-form-group>
                    <b-form-group>
                        <label>Lĩnh vực</label>
                        <b-input v-model="form.linhVuc" readOnly />
                    </b-form-group>
                    <b-form-group>
                        <label>Người tạo</label>
                         <b-input v-model="form.userCreateName" readOnly />
                    </b-form-group>
                    <b-form-group>
                        <label>Ngày tạo</label>
                        <b-input :value="form.ngayTao | formatDate " disabled></b-input>
                        </b-input>
                    </b-form-group>
                    <b-form-group >
                        <label>Nội dung</label>
                        <div class="content-info" style="border: solid 1px #ccc; border-radius: 5px;padding: 10px;" v-html="form.noiDung"></div>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import {
    httpClient
} from "../../../_utils/httpClient";
import _ from "lodash";

export default {
    name: 'create',
    components: {
        PageHeader,
    },
    data: function () {
        return {
            loaiBaiViet: null,
            categories: [],
            editorOption: {},
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Thông tin',
                to: '/admin/informations',
            }, {
                text: 'Chi tiết',
                to: '#',
                active: true,
            }, ],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                ten: '',
                noiDung: '',
                userCreateName: '',
                linhVuc: '',
                ngayTao: '',
            },
            listCoQuanBanHanh: [],
            loading: false,
        };
    },
    watch: {
        'form.preview': {
            handler: function () {
                console.log(this.form.preview);
            },
            deep: true,
        },
    },
    methods: {
        checkLoaiBaiViet(ten){
            if(ten.includes("Thông Báo:")){
                this.loaiBaiViet = "Thông Báo"
            }
            else {
                this.loaiBaiViet = "Quyết Định"
            }
            console.log("Check: ", this.loaiBaiViet);
        },
        async toListView() {
            await this.$router.push({
                name: 'admin.decisions.list'
            })
        },
        async getCoQuanBanHanh() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'coquanbanhanh'
                }
            })
            this.listCoQuanBanHanh = response.data.data.map(el => {
                return el.name
            })
        },
        async getCategories() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'linhvucquyetdinh'
                }
            })
            this.categories = response.data.data.map(el => {
                return el.name
            })
        },
        async getById(id) {
            let response = await httpClient.get(`decision`, {
                params: {
                    id
                }
            })
            this.form = response.data.data
        },

    },
    async created() {
        let cloneItem = _.cloneDeep(this.$route.params.form);
        if (cloneItem === undefined) {
            await this.getById(this.$route.params.id)
        } else {
            this.form = {
                ...cloneItem
            }
        }
        await this.getCoQuanBanHanh();
        await this.getCategories();
        this.checkLoaiBaiViet(this.form.ten);
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}

.ql-toolbar.ql-snow>span {
    display: none !important;
}



</style>
